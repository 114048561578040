import React from "react";
import { twMerge } from "tailwind-merge";
import Settings from "../themes/settings";

import { Badge, Sidebar } from "flowbite-react";
import { BiBasketball, BiFootball, BiSolidTennisBall } from "react-icons/bi";
import {
	HiArrowSmRight,
	HiOutlineMinusSm,
	HiOutlinePlusSm,
	HiShoppingBag,
	HiTable,
	HiUser,
} from "react-icons/hi";
import { IoIosBicycle } from "react-icons/io";
import { MdSportsRugby } from "react-icons/md";
import { TbOlympics } from "react-icons/tb";

export function AppSidebar() {
	return (
		<Sidebar aria-label="Main sidebar">
			<Sidebar.Items className="flex-container">
				<Sidebar.ItemGroup>
					<img src={`${Settings.folder}/logo.png`} />
					{Settings.titre ? (
						<div className="text-2xl font-bold text-center">
							{Settings.titre}
						</div>
					) : (
						""
					)}
				</Sidebar.ItemGroup>
				<Sidebar.ItemGroup>
					<Sidebar.Item
						href="#"
						icon={TbOlympics}
						label="Nouveau !"
						labelColor="blue"
					>
						<p>JO de Paris</p>
					</Sidebar.Item>
					<Sidebar.Item href="#" icon={BiSolidTennisBall} active={true}>
						<p>Tennis</p>
					</Sidebar.Item>
					<Sidebar.Item
						href="#"
						icon={MdSportsRugby}
						label="Premium"
						labelColor="yellow"
					>
						<p>Rugby</p>
					</Sidebar.Item>
					<Sidebar.Item
						href="#"
						icon={BiFootball}
						label="Premium"
						labelColor="yellow"
					>
						<p>Football</p>
					</Sidebar.Item>
					<Sidebar.Item
						href="#"
						icon={BiBasketball}
						label="Premium"
						labelColor="yellow"
					>
						<p>Basket</p>
					</Sidebar.Item>
					<Sidebar.Item
						href="#"
						icon={IoIosBicycle}
						label="Premium"
						labelColor="yellow"
					>
						<p>Cyclisme</p>
					</Sidebar.Item>
					<Sidebar.Collapse
						icon={HiShoppingBag}
						label="Autre sports"
						renderChevronIcon={(theme, open) => {
							const IconComponent = open ? HiOutlineMinusSm : HiOutlinePlusSm;
							return <IconComponent aria-hidden className={twMerge("on")} />;
						}}
					>
						<Sidebar.Item href="#">Golf</Sidebar.Item>
						<Sidebar.Item href="#">Athlétisme</Sidebar.Item>
						<Sidebar.Item href="#">Équitation</Sidebar.Item>
						<Sidebar.Item href="#">Natation</Sidebar.Item>
					</Sidebar.Collapse>
				</Sidebar.ItemGroup>
				<Sidebar.ItemGroup>
					<Sidebar.CTA>
						<div className="mb-3 flex items-center">
							<Badge color="warning">Beta</Badge>
						</div>
						<div className="mb-3 text-sm text-cyan-900 dark:text-gray-400">
							<p>
								Ceci est un demonstrateur fourni par Delos Intelligence. Aucune
								donnée n'est conservée.
							</p>
						</div>
					</Sidebar.CTA>
					<Sidebar.Item href="#" icon={HiUser}>
						<p>Mon compte</p>
					</Sidebar.Item>
					<Sidebar.Item href="#" icon={HiArrowSmRight}>
						<p>Se connecter</p>
					</Sidebar.Item>
					<Sidebar.Item href="#" icon={HiTable}>
						<p>S'inscrire</p>
					</Sidebar.Item>
				</Sidebar.ItemGroup>
			</Sidebar.Items>
		</Sidebar>
	);
}
