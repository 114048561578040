import {
	RenderHighlightsProps,
	Trigger,
	highlightPlugin,
} from "@react-pdf-viewer/highlight";
import { useRef, useState, useEffect } from "react";
import { UserProvider } from "./UserContext";
import { AppSidebar } from "./components/SidebarNew";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Flowbite } from "flowbite-react";
import applyTheme from "./themes/utils";
import Settings from "./themes/settings";
import { HighlightArea } from "@react-pdf-viewer/highlight";
import ViewerPanel from "./panels/ViewerPanel/ViewerPanel";
import ChatPanel from "./panels/ChatPanel/ChatPanel";

function App() {
	const names = Settings.fixed ? Settings.names : [];
	const [currentFile, setCurrentFile] = useState(names[0]);
	const [canAskQuestion, setCanAskQuestion] = useState(false);
	const [shouldReset, setShouldReset] = useState(false);
	const highlightAreas = useRef<HighlightArea[]>([]);

	type uploadedFiles = { [key: string]: string };
	const uploadedFiles = useRef<uploadedFiles>(
		names.reduce(
			(acc, name) => ({
				...acc,
				[name]: "blob:",
			}),
			{},
		),
	);

	useEffect(() => {
		applyTheme(Settings.theme);
	}, []);

	function handleFilesChange(files: string[]) {
		setCanAskQuestion(true);
		uploadedFiles.current = Object.assign({}, uploadedFiles.current, files);
		if (Settings.multiple) {
			delete uploadedFiles.current["UPLOAD-NEW-FILE"];
			uploadedFiles.current["UPLOAD-NEW-FILE"] = "blob:";
		}
		setCurrentFile(Object.keys(files)[0]);
		// setShouldReset(true);
	}

	function renderHighlights(props: RenderHighlightsProps) {
		return (
			<div>
				{highlightAreas.current
					.filter((area) => area.pageIndex === props.pageIndex)
					.map((area, idx) => (
						<div
							key={idx}
							className="highlight-area"
							style={Object.assign(
								{},
								{
									background: "blue",
									opacity: 0.4,
								},
								props.getCssProperties(area, props.rotation),
							)}
						/>
					))}
			</div>
		);
	}

	const highlightPluginInstance = highlightPlugin({
		renderHighlights,
		trigger: Trigger.None,
	});

	const { jumpToHighlightArea } = highlightPluginInstance;

	function handleReferenceClick(
		doc: string,
		page: string,
		left: string,
		top: string,
		width: string,
		height: string,
	) {
		const area = {
			pageIndex: parseInt(page),
			left: 100 * parseFloat(left),
			width: 100 * parseFloat(width),
			top: 100 * parseFloat(top),
			height: 100 * parseFloat(height),
		};
		const docIndex = parseInt(doc) - 1;
		setCurrentFile(Object.keys(uploadedFiles.current)[docIndex]);
		jumpToHighlightArea(area);
		highlightAreas.current = [area];
	}

	return (
		<UserProvider>
			<Flowbite>
				<div>
					<main
						className={`flex h-screen w-screen flex-col text-sm text-text-base overflow-none`}
					>
						<div className="flex h-[100vh] max-h-full w-full pt-[48px] sm:pt-0 overflow-none">
							<AppSidebar />
							<div className="relative flex-1 bg-white">
								{Settings.panels.includes("PDFViewer") &&
								Settings.panels.includes("Chat") ? (
									<Splitter className="h-[100vh] max-h-full bg-red">
										<SplitterPanel className="">
											<ViewerPanel
												currentFile={currentFile}
												setCurrentFile={setCurrentFile}
												uploadedFiles={uploadedFiles}
												handleFilesChange={handleFilesChange}
												highlightPluginInstance={highlightPluginInstance}
											/>
										</SplitterPanel>
										<SplitterPanel className="h-full max-h-full">
											<ChatPanel
												shouldReset={shouldReset}
												setShouldReset={setShouldReset}
												canAskQuestion={canAskQuestion}
												setCanAskQuestion={setCanAskQuestion}
												uploadedFiles={uploadedFiles}
												handleReferenceClick={handleReferenceClick}
											/>
										</SplitterPanel>
									</Splitter>
								) : Settings.panels.includes("PDFViewer") ? (
									<ViewerPanel
										currentFile={currentFile}
										setCurrentFile={setCurrentFile}
										uploadedFiles={uploadedFiles}
										handleFilesChange={handleFilesChange}
										highlightPluginInstance={highlightPluginInstance}
									/>
								) : Settings.panels.includes("Chat") ? (
									<ChatPanel
										shouldReset={shouldReset}
										setShouldReset={setShouldReset}
										canAskQuestion={canAskQuestion}
										setCanAskQuestion={setCanAskQuestion}
										uploadedFiles={uploadedFiles}
										handleReferenceClick={handleReferenceClick}
									/>
								) : (
									<></>
								)}
							</div>
						</div>
					</main>
				</div>
			</Flowbite>
		</UserProvider>
	);
}

export default App;
