import React, { ChangeEventHandler, DragEventHandler, ReactNode } from "react";

interface GenericDropZoneProps {
	content: ReactNode;
	onDrop: DragEventHandler;
	onDragOver: DragEventHandler;
	onFileChange: ChangeEventHandler;
}

function GenericDropZone({
	content,
	onDrop,
	onDragOver,
	onFileChange,
}: GenericDropZoneProps) {
	return (
		<div className="mx-auto flex w-[50px] h-[50px] flex-col space-y-10 pt-12">
			<div
				className="flex h-full flex-col space-y-4 rounded border border-neutral-200 p-4 bg-white/20"
				onDrop={onDrop}
				onDragOver={onDragOver}
			>
				<div className="flex items-center justify-center w-full">
					<label htmlFor="dropzone-file">
						{content}
						<input
							id="dropzone-file"
							type="file"
							accept="application/pdf application/doc"
							multiple
							onChange={onFileChange}
							className="hidden"
						/>
					</label>
				</div>
			</div>
		</div>
	);
}

export function LoadingDropZone({}): ReactNode {
	const content = <div></div>;
	return (
		<GenericDropZone
			content={content}
			onDrop={() => {}}
			onDragOver={() => {}}
			onFileChange={() => {}}
		/>
	);
}

interface DropZone {
	onDrop: DragEventHandler;
	onDragOver: DragEventHandler;
	onFileChange: ChangeEventHandler;
}
export function DropZone({ onDrop, onDragOver, onFileChange }: DropZone) {
	const content = (
		<div className="flex flex-col items-center justify-center pt-5 pb-6">
			<svg
				aria-hidden="true"
				className="w-10 h-10 mb-3 text-gray-400"
				fill="none"
				stroke="currentColor"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
				/>
			</svg>
		</div>
	);
	return (
		<GenericDropZone
			content={content}
			onDrop={onDrop}
			onDragOver={onDragOver}
			onFileChange={onFileChange}
		/>
	);
}
