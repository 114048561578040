import Themes, { Theme } from "./themes";
import { BiPaperPlane } from "react-icons/bi";
import { FaWineBottle } from "react-icons/fa";

type Settings = {
	theme: Theme;
	folder: string;
	listIcon: any;
	titre: string;
	panels: string[];
	multiple: boolean;
	autoload: boolean;
	autoloadFile?: string;
	fixed: boolean;
	names: string[];
	left: string;
	right: string;
	first: string;
};

const DelosDocsSettings: Settings = {
	theme: Themes.DelosDocs,
	folder: "DelosDocs",
	listIcon: BiPaperPlane,
	titre: "",
	panels: ["Thumbnails", "PDFViewer", "Chat"],
	multiple: true,
	autoload: false,
	fixed: false,
	names: [],
	left: "Chargez vos documents ici",
	right: "Discutez avec vos documents",
	first: `
Bienvenue dans **DelosDocs**! Chargezz vos documents et commencez une discussion *avec* eux ! Voici quelques questions pour bien demarrer :

- <button class='question'>De quoi traitent ces documents ?</button>
- <button class='question'>Mets moi un descriptif rapide de chaque document dans un tableau.</button>

Ou bien posez toute autre question dans la bare de chat en bas de l'écran !
`,
};

const DelosTabsSettings: Settings = {
	theme: Themes.DelosDocs,
	folder: "DelosDocs",
	listIcon: BiPaperPlane,
	titre: "",
	panels: ["Thumbnails", "PDFViewer", "Chat"],
	multiple: true,
	autoload: false,
	fixed: false,
	names: [],
	left: "Chargez vos documents ici",
	right: "Discutez avec vos documents",
	first: `
Bienvenue dans **DelosDocs**! Chargezz vos documents et commencez une discussion *avec* eux ! Voici quelques questions pour bien demarrer :

- <button class='question'>De quoi traitent ces documents ?</button>
- <button class='question'>Mets moi un descriptif rapide de chaque document dans un tableau.</button>

Ou bien posez toute autre question dans la bare de chat en bas de l'écran !
`,
};

const iDealWineSettings: Settings = {
	theme: Themes.iDealWine,
	folder: "iDealWine",
	listIcon: FaWineBottle,
	titre: "Wine expert",
	panels: ["Chat", "PDFViewer"],
	multiple: true,
	autoload: true,
	fixed: false,
	names: [],
	left: "Charger les documents ici",
	right: "Posez vos questions à notre expert !",
	first: `
Bienvenue dans l'expert en vins d'**iDealWine** ! Vous pouvez poser ici toutes vos questions sur le vin. Voici quelques exemples de questions :

- <button class='question'> Que veut dire un vin *bouchonné* ? </button>
- <button class='question'> Quels sont les plus grands vins de Bordeaux ? </button>
- <button class='question'> Comment fait-on le Sauternes ? </button>

Ou posez toute autre question dans la barre du bas !
`,
};

const lEquipeSettings: Settings = {
	theme: Themes.lEquipe,
	folder: "lEquipe",
	listIcon: BiPaperPlane,
	titre: "EXPERT SPORTIF",
	panels: ["Chat"],
	multiple: false,
	autoload: true,
	fixed: true,
	names: ["Tennis.pdf"],
	left: "BDD Sportive",
	right: "Posez vos questions à notre expert !",
	first: `Bienvenue sur le chatbot expert de **_L'Équipe_**, qui saura vour renseigner sur toutes vos 
questions sur le tennis ! Voici quelques propositions :
- <button class='question'>Qui est le joueur de tennis masculin ayant remporté le plus grand nombre de titres en Grand Chelem, et combien de titres a-t-il gagnés ?</button>
- <button class='question'>Qui est le joueur ayant remporté le plus de fois le tournoi de Wimbledon ?</button>
- <button class='question'>Pouvez-vous me parler de la rivalité historique entre Roger Federer, Rafael Nadal et Novak Djokovic, en soulignant leurs réalisations individuelles et leurs affrontements en Grand Chelem ?</button>
- <button class='question'>Qui est la plus jeune gagnante de tous les temps d'un tournoi du Grand Chelem en simple, et quel tournoi était-ce ?</button>
- <button class='question'>Quelle était la date de la création de l'Association des joueurs de tennis professionnels (ATP) ?</button>
    `,
};

const MercerSettings: Settings = {
	theme: Themes.Mercer,
	folder: "Mercer",
	listIcon: BiPaperPlane,
	titre: "",
	panels: ["Thumbnails", "PDFViewer", "Chat"],
	multiple: false,
	autoload: false,
	fixed: true,
	names: [
		"Dernier bulletin de paie.pdf",
		"Fiche de renseignements.pdf",
		"Récapitulatif régime général.pdf",
		"Relevé AGIRC ARRCO.pdf",
	],
	left: "Charger les documents ici",
	right: "Traitement des données",
	first:
		"Bienvenue dans l'outil de calcul de retraites de Mercer ! Chargez des documents, et commencer à discuter avec ceux-ci.",
};

const Settings = lEquipeSettings;
export default Settings;
