import Settings from "../../themes/settings";
import Chat from "../../features/Chat/Chat";

interface ChatPanel {
	shouldReset: boolean;
	setShouldReset: React.Dispatch<boolean>;
	canAskQuestion: boolean;
	setCanAskQuestion: React.Dispatch<boolean>;
	uploadedFiles: React.RefObject<any>;
	handleReferenceClick: any;
}
export default function ChatPanel({
	shouldReset,
	setShouldReset,
	canAskQuestion,
	setCanAskQuestion,
	uploadedFiles,
	handleReferenceClick,
}: ChatPanel) {
	return (
		<div className="flex flex-col flex-auto h-full max-h-full">
			<div className="flex p-3 bg-secondary shadow">
				<h3 className="px-4 text-lg text-text text-center">{Settings.right}</h3>
			</div>
			<div className="flex flex-col flex-auto h-full max-h-full bg-background">
				<Chat
					shouldReset={shouldReset}
					setShouldReset={setShouldReset}
					uploadedFiles={uploadedFiles}
					handleReferenceClick={handleReferenceClick}
					canAskQuestion={canAskQuestion}
					setCanAskQuestion={setCanAskQuestion}
				/>
			</div>
		</div>
	);
}
