export type Theme = {
	[key: string]: string;
};

const DelosDocsTheme: Theme = {
	"--theme-primary": "blue",
	"--theme-primary-hover": "purple",
	"--theme-primary-light": "purple",

	"--theme-secondary": "#475569",
	"--theme-secondary-hover": "purple",
	"--theme-secondary-light": "#e4f2ff",

	"--theme-warning": "#e3a008",
	"--theme-warning-light": "#f1face",
	"--theme-warning-hover": "#f1face",

	"--theme-danger": "#f1face",
	"--theme-danger-light": "#f1face",
	"--theme-danger-hover": "#f1face",

	"--theme-background": "#f1f5f9",
	"--theme-background-light": "#f8f8f8",
	"--theme-background-hover": "blue",

	"--theme-sidebar": "#253548",
	"--theme-sidebar-light": "blue",
	"--theme-sidebar-hover": "blue",

	"--theme-text-base": "black",
	"--theme-text-inv": "white",
	"--theme-text-font-sans": "IBM Plex Sans",

	"--theme-border-radius-sm": "2px",
	"--theme-border-radius-md": "4px",
	"--theme-border-radius-lg": "8px",
};

const iDealWineTheme: Theme = {
	"--theme-primary": "#c5a864",
	"--theme-primary-hover": "purple",
	"--theme-primary-light": "purple",

	"--theme-secondary": "#c5a864",
	"--theme-secondary-hover": "purple",
	"--theme-secondary-light": "#e4f2ff",

	"--theme-warning": "#e3a008",
	"--theme-warning-light": "#f1face",
	"--theme-warning-hover": "#f1face",

	"--theme-danger": "#f1face",
	"--theme-danger-light": "#f1face",
	"--theme-danger-hover": "#f1face",

	"--theme-background": "#eee",
	"--theme-background-light": "#f8f8f8",
	"--theme-background-hover": "blue",

	"--theme-sidebar": "#fff",
	"--theme-sidebar-light": "blue",
	"--theme-sidebar-hover": "blue",

	"--theme-text-base": "black",
	"--theme-text-inv": "black",
	"--theme-text-font-sans": "IBM Plex Sans",

	"--theme-border-radius-sm": "2px",
	"--theme-border-radius-md": "4px",
	"--theme-border-radius-lg": "8px",
};

const lEquipeTheme: Theme = {
	"--theme-primary": "#f80000",
	"--theme-primary-hover": "#f80000b0",
	"--theme-primary-light": "#f80000b0",

	"--theme-secondary": "#fff",
	"--theme-secondary-hover": "#e6e6e6",
	"--theme-secondary-light": "#e6e6e6",

	"--theme-warning": "#f1face",
	"--theme-warning-light": "#f1face",
	"--theme-warning-hover": "#f1face",

	"--theme-danger": "#f1face",
	"--theme-danger-light": "#f1face",
	"--theme-danger-hover": "#f1face",

	"--theme-background": "f8f8f8",
	"--theme-background-light": "f8f8f8",
	"--theme-background-hover": "blue",

	"--theme-sidebar": "#111",
	"--theme-sidebar-light": "blue",
	"--theme-sidebar-hover": "blue",

	"--theme-text-base": "black",
	"--theme-text-inv": "white",
	"--theme-text-font-sans": "DINNextLTPro-Regular",

	"--theme-border-radius-sm": "2px",
	"--theme-border-radius-md": "4px",
	"--theme-border-radius-lg": "8px",
};

const Themes: { [key: string]: Theme } = {
	DelosDocs: DelosDocsTheme,
	lEquipe: lEquipeTheme,
	iDealWine: iDealWineTheme,
};

export default Themes;
