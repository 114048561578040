import React from "react";
import utf8 from "utf8";
import { ChatMessage } from "../types";

// Fonction pour convertir les caractères unicode en utf8
function convertUnicodeToString(unicodeString: string) {
	const unicodeArray = unicodeString.split(",");
	const charArray = unicodeArray.map((code) =>
		String.fromCharCode(parseInt(code, 10)),
	);
	return charArray.join("");
}

export default async function sendQuestion(
	question: string,
	setCanAskQuestion: React.Dispatch<boolean>,
	setChatMessages: React.Dispatch<any>,
	chatMessages: ChatMessage[],
	uploadedFiles: any,
	userId: string,
) {
	setCanAskQuestion(false);
	const newQuestionMessage = {
		type: "question",
		text: question,
	};

	// Utilisation du callback pour mettre à jour chatMessages avec la nouvelle question
	setChatMessages((prevChatMessages: ChatMessage[]) => [
		...prevChatMessages,
		newQuestionMessage,
	]);

	// Stockage de chatMessages dans une variable temporaire
	const updatedChatMessages = [...chatMessages, newQuestionMessage];

	// Création de la chaîne de texte
	const chatBufferText = updatedChatMessages
		.map((message) => {
			return message.type === "question"
				? `user: ${message.text}`
				: message.type === "answer"
				? `system: ${message.text}`
				: "";
		})
		.join("\n");

	const askRequest = {
		question: newQuestionMessage.text, // Utilisation du texte actuel de l'input
		chat_buffer: chatBufferText, // Historique des messages reçus et envoyés
		filenames: Object.keys(uploadedFiles.current).filter(
			(fn) => fn !== "UPLOAD-NEW-FILE",
		),
	};
	const content = JSON.stringify(askRequest);

	// Réception de la réponse du serveur suite a l'envoi de askRequest
	const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/ask`, {
		method: "POST",
		body: JSON.stringify(askRequest),
		headers: {
			"Content-Type": "application/json",
			"user-id": userId,
		},
	});

	//Création d'un message vide où la réponse s'écrira.
	setChatMessages((prevChatMessages: any) => [
		...prevChatMessages,
		{ type: "answer", text: "" },
	]);

	// Vérification si la requête a réussi (statut HTTP 200)
	if (response.status === 200 && response.body) {
		// Création d'un ReadableStream à partir de la réponse
		const stream = response.body;

		// Création d'une variable pour stocker les données de la réponse
		let responseData = "";

		// Création d'un StreamReader pour lire le flux
		const reader = stream.getReader();

		// Lecture du flux chunk par chunk
		const readStream = async () => {
			const { done, value } = await reader.read();
			if (!done) {
				responseData = utf8.decode(convertUnicodeToString(value.toString())); // Conversion du chunk en texte et ajoutez le à responseData
				setChatMessages((prevChatMessages: ChatMessage[]) => {
					const lastMessageIndex = prevChatMessages.length - 1;
					const updatedLastMessage = {
						...prevChatMessages[lastMessageIndex],
						type: "answer",
						text:
							prevChatMessages[lastMessageIndex].text +
							responseData
								.replace(/\n\n\n/g, "TRIPLE_RETURN")
								.replace(/\n\n/g, "DOUBLE_RETURN")
								.replace(/\n/g, "")
								.replace(/TRIPLE_RETURN/g, "<br><br>\n\n")
								.replace(/DOUBLE_RETURN/g, "\n"),
					};
					return [
						...prevChatMessages.slice(0, lastMessageIndex),
						updatedLastMessage,
					];
				});
				readStream(); // Continue à lire le flux
			} else {
				setCanAskQuestion(true);
			}
		};

		readStream(); // Commence à lire le flux
	} else {
		console.error("Erreur lors de la requête API");
	}
}
